.home-container {
  height: 100vh;
}

.intro-container {
  position: absolute;
  top: 60px;
  left: 0;
  background-image: url("../../assets/VOD.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 700px;
  text-align: center;
  padding: 30px;
}

.text-with-background {
  margin-top: 15px;
  padding: 10px;
  background: #383838ad;
  color: #ffffff;
  border-radius: 15px;
}

.text-with-background h1, h2 {
  padding: 20px;
  background: #0000008c;
  border-radius: 15px;
}

.decorated-iframe {
  position: relative;
  margin: 80px auto;
  background-color: #28090b;
  border: 8px solid #110d35e8;
  border-radius: 20px;
  max-width: 700px;
  height: 400px;
  border-radius: 5px;
  box-shadow: 1px 1px 150px #fff;
}
