.contact-us-container {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    height: 251px;
}

.contact-us {
    box-shadow: 1px 1px 45px #fff;
    font-size: 22px;
    font-size: Helvetica;
    font-weight: 600;
    color: #fff;
    padding: 20px;
}

.contact-logo {
    margin-top: 5px;
    border-radius: 15px;
    padding: 5px;
    width: 80px;
    background: #fff;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.contact-logo:hover {
    transform: scale(1.1);
    box-shadow: 1px 1px 12px #fff;
}

.image {
    margin-right: 10px;
}