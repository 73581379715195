body {
  background: #1a2b35;
  color: #000;
  font-family: Helvetica;
  font-weight: 550;
  font-size: 14px;
  height: 100vh;
  overflow-y: auto;
}

html, body, #root, .app {
  height: 100vh;
}
