.footer-container {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000000;
    height: 40px;
    padding: 10px;
    color: #fff;
}

.a-footer {
    cursor: pointer;
    color: #ffffff;
    text-decoration: underline;
}

.a-footer:hover {
    color: #ffffffb2;
}

.license {
    position: absolute;
    float: right;
    right: 10px;
}

.social-networks-logos {
    border-radius: 15px;
    padding-right: 2px;
    padding-left: 2px;
    padding-bottom: 22px;
    background: #fff;
    position: relative;
    margin-right: 15px;
    border-radius: 2px;
}

.social-networks-logos:hover {
    transform: scale(1.1);
    box-shadow: 1px 1px 12px #fff;
}

.scale{
    border-radius: 2px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.scale:hover {
    transform: scale(1.1);
    box-shadow: 1px 1px 12px #fff;
}