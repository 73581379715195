.products-container {
  padding: 40px;
  margin-bottom: 40px;
}

.products-us-text {
  padding: 15px;
  text-align: left;
  font-family: Helvetica;
  font-size: 21px;
  font-weight: 500;
  white-space: pre-wrap;
  overflow: hidden;
}
