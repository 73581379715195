.content {
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .content {
    margin-top: -60px !important;
    position: absolute;
    width: 100%;
  }
}
