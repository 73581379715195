.navbar {
  background: #1a2b35 !important;
  color: #fff !important;
  padding-bottom: 5px;
  box-shadow: 1px 1px 25px rgb(0, 0, 0);
  width: 100%;
  z-index: 1000;
}

.nav {
  background: #1a2b35 !important;
  position: "relative";
  top: 4px;
  z-index: 1000;
}

@media only screen and (max-width: 600px) {
  .nav {
    width: 100%;
  }
}

.rs-navbar-item.rs-navbar-item-active {
  color: #fff !important;
  border-bottom: 3px solid #ffffff;
  background: #1a2b35 !important;
}

.footer-container {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000000;
    height: 40px;
    padding: 10px;
    color: #fff;
}

.a-footer {
    cursor: pointer;
    color: #ffffff;
    text-decoration: underline;
}

.a-footer:hover {
    color: #ffffffb2;
}

.license {
    position: absolute;
    float: right;
    right: 10px;
}

.social-networks-logos {
    border-radius: 15px;
    padding-right: 2px;
    padding-left: 2px;
    padding-bottom: 22px;
    background: #fff;
    position: relative;
    margin-right: 15px;
    border-radius: 2px;
}

.social-networks-logos:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 1px 1px 12px #fff;
}

.scale{
    border-radius: 2px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.scale:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 1px 1px 12px #fff;
}
.content {
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .content {
    margin-top: -60px !important;
    position: absolute;
    width: 100%;
  }
}

.contact-us-container {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    height: 251px;
}

.contact-us {
    box-shadow: 1px 1px 45px #fff;
    font-size: 22px;
    font-size: Helvetica;
    font-weight: 600;
    color: #fff;
    padding: 20px;
}

.contact-logo {
    margin-top: 5px;
    border-radius: 15px;
    padding: 5px;
    width: 80px;
    background: #fff;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.contact-logo:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 1px 1px 12px #fff;
}

.image {
    margin-right: 10px;
}
.home-container {
  height: 100vh;
}

.intro-container {
  position: absolute;
  top: 60px;
  left: 0;
  background-image: url(/static/media/VOD.ccefd171.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 700px;
  text-align: center;
  padding: 30px;
}

.text-with-background {
  margin-top: 15px;
  padding: 10px;
  background: #383838ad;
  color: #ffffff;
  border-radius: 15px;
}

.text-with-background h1, h2 {
  padding: 20px;
  background: #0000008c;
  border-radius: 15px;
}

.decorated-iframe {
  position: relative;
  margin: 80px auto;
  background-color: #28090b;
  border: 8px solid #110d35e8;
  border-radius: 20px;
  max-width: 700px;
  height: 400px;
  border-radius: 5px;
  box-shadow: 1px 1px 150px #fff;
}

.about-container {
  padding: 40px;
  margin-bottom: 40px;
}

.about-us-text {
  padding: 15px;
  text-align: left;
  font-family: Helvetica;
  font-size: 21px;
  font-weight: 500;
  white-space: pre-wrap;
  overflow: hidden;
}

.products-container {
  padding: 40px;
  margin-bottom: 40px;
}

.products-us-text {
  padding: 15px;
  text-align: left;
  font-family: Helvetica;
  font-size: 21px;
  font-weight: 500;
  white-space: pre-wrap;
  overflow: hidden;
}

.contact-container {
  padding: 40px;
  margin-bottom: 40px;
}

.contact-us-text {
  padding: 15px;
  text-align: left;
  font-family: Helvetica;
  font-size: 21px;
  font-weight: 500;
  white-space: pre-wrap;
  overflow: hidden;
}

body {
  background: #1a2b35;
  color: #000;
  font-family: Helvetica;
  font-weight: 550;
  font-size: 14px;
  height: 100vh;
  overflow-y: auto;
}

html, body, #root, .app {
  height: 100vh;
}

