.navbar {
  background: #1a2b35 !important;
  color: #fff !important;
  padding-bottom: 5px;
  box-shadow: 1px 1px 25px rgb(0, 0, 0);
  width: 100%;
  z-index: 1000;
}

.nav {
  background: #1a2b35 !important;
  position: "relative";
  top: 4px;
  z-index: 1000;
}

@media only screen and (max-width: 600px) {
  .nav {
    width: 100%;
  }
}

.rs-navbar-item.rs-navbar-item-active {
  color: #fff !important;
  border-bottom: 3px solid #ffffff;
  background: #1a2b35 !important;
}
